* {
  font-family: 'StuBru';
  color: rgb(206, 40, 39);
}

html,
body {
  overflow: hidden;
}

#root {
  overflow: hidden;
  background: black;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: black;
}

.App {
  height: 100%;
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  color: rgb(206, 40, 39);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

h1 {
  /* text-shadow: 3px 3px 3px rgba(51, 51, 51, 0.9); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-size: 3em;
  margin-bottom: 1em;
  font-family: 'PoliticaW00-BlackXp', sans-serif;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-container strong {
  font-weight: 700;
  width: max-content;
  margin-top: 1em;
  font-size: 1.2em;
  color: white;
}

.link-container p {
  font-size: 2em;
}

.link-container a {
  text-decoration: none;
  background-color: rgb(206, 40, 39);
  color: black;
  padding: 0.7em 1.2em;
  font-size: 1.2em;
  width: max-content;
  font-family: 'PoliticaW00-BlackXp', sans-serif;
}

.link-container span {
  text-decoration: none;
  font-weight: 700;
  font-size: 0.8em;
  margin-top: 0.5em;
  width: max-content;
  font-family: 'PoliticaW00-BlackXp', sans-serif;
}

.logo img {
  height: 50px;
}

p {
  font-size: 1.2em;
  color: white;
}

.footer {
  position: absolute;
  bottom: 0.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-icons * {
  color: white !important;
}

.social-icons {
  position: absolute;
  z-index: 1;
  bottom: 0.5em;
  left: calc(50% - 16px - 1em);
}

.social-icons button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.social-icons svg {
  height: 1.5em !important;
  width: 1.5em !important;
  margin-left: 0.5em;
}

.link-container p {
  font-size: '3em';
  font-family: 'PoliticaW00-BlackXp', sans-serif;
}

.logo-img {
  height: 70px;
}

@media only screen and (max-width: 480px) {
  #root {
    overflow-y: auto;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  .link-container span {
    font-size: 0.7em;
  }

  .link-container strong {
    font-weight: 700;
    width: max-content;
    padding-top: 1em;
    font-size: 1em;
    color: white;
  }

  .link-container a {
    text-decoration: none;
    color: black;
    padding: 0.2em;
    font-size: 1.2em;
    width: max-content;
  }

  .social-icons svg {
    height: 1em !important;
    width: 1em !important;
    margin-left: 0.5em;
  }

  .link-container p {
    font-size: 1.5em;
  }

  body {
    overflow-y: scroll;
  }

  .footer-text {
    font-size: 0.7em;
    width: 30%;
  }

  .logo-img {
    height: 25px;
    width: 80%;
  }

  .social-icons svg {
    height: 1.5em !important;
    width: 1.5em !important;
    margin-left: 0.5em;
  }
}
